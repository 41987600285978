<template>
  <div>
    <p class="clearfix mb-0">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        COPYRIGHT © {{ new Date().getFullYear() }}
        <b-link class="ml-25" href="https://avonale.com.br" target="_blank"
          >Avonale</b-link
        >
        <span class="d-none d-sm-inline-block"
          >, Todos os direitos reservados</span
        >
      </span>
      <span class="float-md-right d-none d-md-block">
        <b-img
          :src="appIconImage"
          width="35"
          alt="Ícone do empresa do aplicativo"
        />
      </span>
    </p>
    <b-modal
      id="modal-refresh-token"
      ok-only
      ok-title="Logar"
      centered
      title="Sessão expirada"
      v-model="modalShow"
      size="xs"
    >
      <b-form-group label="Nome" label-for="name">
        <b-form-input id="name" autofocus trim />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { BImg, BLink, BModal, BFormGroup, BFormInput } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BImg,
    BLink,
    BModal,
    BFormGroup,
    BFormInput,
  },
  data() {
    let { appIconImage } = $themeConfig.app;

    return {
      appIconImage,
      modalShow: false,
    };
  },
};
</script>
