






import useAppConfig from "@core/app-config/useAppConfig";
import { BNavItem } from "bootstrap-vue";
import { Component, Watch, Vue } from "vue-property-decorator";

@Component({
  name: "DarkToggler",
  components: {
    BNavItem,
  },
})
export default class DarkToggler extends Vue {
  skin = useAppConfig().skin;

  get isDark(): boolean {
    return this.skin.value === "dark";
  }

  @Watch("isDark")
  toggleDarkMode() {
    const iframe: HTMLIFrameElement | null = document.querySelector(
      "#embed-emplacamento iframe"
    );

    if (iframe) {
      // @ts-ignore
      iframe.contentWindow.postMessage({ isDark: this.isDark }, "*");
    }
  }
}
