import events from "@/resources/systems-modules-events/events.json";

export default [
  {
    header: "Painel Administrativo",
    action: "read",
    resource: "Auth",
  },
  {
    title: "Usuários",
    icon: "UserPlusIcon",
    route: "admin-panel-users-list",
    action: "manage",
    resource: events.Emp_Usuarios.id,
  },
  {
    title: "Usuários Pendentes",
    icon: "UserCheckIcon",
    route: "admin-panel-pending-users",
    action: "manage",
    resource: events.Emp_Usuários_Pendentes.id,
  },
  {
    title: "Empresas",
    icon: "TruckIcon",
    route: "admin-panel-dealerships-list",
    action: "manage",
    resource: events.Emp_Empresas.id,
  },
  {
    title: "Grupo Econômico",
    icon: "DollarSignIcon",
    route: "admin-panel-group-economic-list",
    action: "manage",
    resource: events.Emp_Grupos_Economicos.id,
  },
  {
    title: "Grupo Empresa",
    icon: "BriefcaseIcon",
    route: "admin-panel-group-list",
    action: "manage",
    resource: events.Emp_Grupos.id,
  },
  {
    title: "Localidade",
    icon: "MapPinIcon",
    action: "manage",
    resource: events.Emp_Municipios.id,
    children: [
      {
        title: "Região Operacional",
        route: "admin-panel-regions-list",
        action: "manage",
        resource: events.Emp_Municipios.id,
      },
      {
        title: "Área Operacional",
        route: "admin-panel-area-list",
        action: "manage",
        resource: events.Emp_Municipios.id,
      },
      {
        title: "Municípios",
        route: "admin-panel-county-list",
        action: "manage",
        resource: events.Emp_Municipios.id,
      },
    ],
  },
  {
    title: "Nomenclaturas",
    icon: "ListIcon",
    route: "admin-panel-vehicle-version",
    action: "manage",
    resource: events.Emp_Nomenclaturas.id,
  },
  {
    title: "Validação da Marca",
    icon: "CheckCircleIcon",
    route: "admin-panel-brand-validation",
    action: "manage",
    resource: events.Emp_Validacao_da_marca.id,
  },
  // {
  //   title: "Imp. Emplacamento",
  //   icon: "DownloadIcon",
  //   route: "admin-panel-import-emplacamento",
  //   action: "manage",
  //   resource: events.Emp_Importar_Emplacamentos.id,
  // },
  {
    title: "Imp. Faturamento",
    icon: "DownloadIcon",
    route: "admin-panel-import-billing",
    action: "manage",
    resource: events.Emp_Importar_Faturamentos.id,
  },
  {
    title: "Efetivar Faturamento",
    icon: "SaveIcon",
    route: "admin-panel-effectivate-billing",
    action: "manage",
    resource: events.Emp_Efetivar_Faturamentos.id,
  },
];
