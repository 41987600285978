import events from "@/resources/systems-modules-events/events.json";

export default [
  {
    title: "Dashboard KPI",
    route: "dashboard-kpi",
    icon: "ActivityIcon",
    action: "manage",
    resource: events.Emp_Dashboard_Kpi.id,
  },
  {
    title: "Relatórios",
    icon: "BarChart2Icon",
    children: [
      {
        title: "Boletim Diário",
        route: "reports-daily-report",
        action: "manage",
        resource: events.Emp_Boletim_Diario.id,
      },
      {
        title: "Da Sua Concessionária",
        route: "reports-from-your-dealership",
        action: "manage",
        resource: events.Emp_Da_sua_concessionaria.id,
      },
      {
        title: "Por Cidade - Concessionária",
        route: "reports-by-city-dealership",
        action: "manage",
        resource: events.Emp_Por_cidade_concessionaria.id,
      },
      {
        title: "Por Marcas",
        route: "reports-by-brands",
        action: "manage",
        resource: events.Emp_Por_marcas.id,
      },
      {
        title: "Por Marcas Últimos 12 meses",
        route: "reports-by-brand-last-12-months",
        action: "manage",
        resource: events.Emp_Por_marcas_ultimos_12_meses.id,
      },
      {
        title: "Por Marcas Ano a Ano",
        route: "reports-by-brands-year-by-year",
        action: "manage",
        resource: events.Emp_Por_marcas_ano_a_ano.id,
      },
      {
        title: "Por Área de Influência",
        route: "reports-by-area-of-influence",
        action: "manage",
        resource: events.Emp_Por_area_de_influencia.id,
      },
      {
        title: "Por Regiões",
        route: "reports-by-regions",
        action: "manage",
        resource: events.Emp_Por_regioes.id,
      },
      {
        title: "Por Modelo Ano a Ano",
        route: "reports-by-model-year-by-year",
        action: "manage",
        resource: events.Emp_Por_modelo_ano_a_ano.id,
      },
      {
        title: "Por Modelo Mês a Mês",
        route: "reports-by-model-month-by-month",
        action: "manage",
        resource: events.Emp_Por_modelo_mes_a_mes.id,
      },
      {
        title: "Ranking de Modelos",
        route: "reports-model-ranking",
        action: "manage",
        resource: events.Emp_Ranking_de_modelos.id,
      },
      {
        title: "Ranking de Cidades",
        route: "reports-city-ranking",
        action: "manage",
        resource: events.Emp_Ranking_cidades.id,
      },
      {
        title: "Segmento Acumulado",
        route: "reports-accumulated-segment",
        action: "manage",
        resource: events.Emp_Segmento_acumulado.id,
      },
      {
        title: "Segmento Mês a Mês",
        route: "reports-segment-by-month",
        action: "manage",
        resource: events.Emp_Segmento_mes_a_mes.id,
      },
      {
        title: "Ranking de Concessionárias",
        route: "reports-ranking-of-dealerships",
        action: "manage",
        resource: events.Emp_Ranking_de_concessionarias.id,
      },
      {
        title: "Informações de Veículos",
        route: "reports-vehicle-information",
        action: "manage",
        resource: events.Emp_Informacoes_de_veiculos.id,
      },
      {
        title: "Histórico de Acesso",
        route: "reports-access-history",
        action: "manage",
        resource: events.Emp_Historico_dos_acessos.id,
      },
      {
        title: "Acesso - Por Usuário",
        route: "reports-access-reports-by-users",
        action: "manage",
        resource: events.Emp_Relatorios_de_acesso_por_usuario.id,
      },
      {
        title: "Acesso - Por Grupo/Empresa",
        route: "reports-access-report-by-group-company",
        action: "manage",
        resource: events.Emp_Relatorios_de_acesso_por_grupo_empresa.id,
      },
      {
        title: "Acessos - Por Relatório",
        route: "reports-access-report-by-report",
        action: "manage",
        resource: events.Emp_Relatorios_de_acesso_por_relatorio.id,
      },
      {
        title: "Invasão",
        route: "reports-invasion",
        action: "manage",
        resource: events.Emp_Invasao.id,
      },
      {
        title: "Capitais Anual",
        route: "reports-annual-capital",
        action: "manage",
        resource: events.Emp_Capitais_anual.id,
      },
      {
        title: "Capitais Mensal",
        route: "reports-monthly-capital",
        action: "manage",
        resource: events.Emp_Capitais_mensal.id,
      },
      {
        title: "Capitais e Marcas",
        route: "reports-capital-brands",
        action: "manage",
        resource: events.Emp_Capitais_e_marcas.id,
      },
      {
        title: "Ranking Anual Por Marcas",
        route: "reports-annual-ranking-by-brands",
        action: "manage",
        resource: events.Emp_Ranking_Anual_por_marcas.id,
      },
      {
        title: "Tabela Dinâmica",
        route: "reports-dynamic-table",
        action: "manage",
        resource: events.Emp_Tabela_Dinamica.id,
      },
      {
        title: "Regiões, Áreas e Municípios",
        route: "reports-regions-areas-cities",
        action: "manage",
        resource: events.Emp_Regioes_Areas_e_Municipios.id,
      },
      {
        title: "Faturamento",
        route: "reports-billing-report",
        action: "manage",
        resource: events.Emp_Relatorio_de_Faturamentos.id,
      },
      {
        title: "Faturamento DVR",
        route: "reports-invoicing-dvr",
        action: "manage",
        resource: events.Emp_Faturamento_DVR.id,
      },
      {
        title: "Faturamento DVE",
        route: "reports-invoicing-dve",
        action: "manage",
        resource: events.Emp_Faturamento_DVE.id,
      },
      {
        title: "Faturamento Consolidado",
        route: "reports-invoicing-consolidated",
        action: "manage",
        resource: events.Emp_Faturamento_Consolidado.id,
      },
      {
        title: "Duplo Emplacamento",
        route: "reports-dual-plate",
        action: "manage",
        resource: events.Emp_Duplo_emplacamento.id,
      },
    ],
  },
  {
    title: "Gráficos",
    icon: "PieChartIcon",
    children: [
      {
        title: "Tipo de Vendas",
        route: "reports-sales-type-chart",
        action: "manage",
        resource: events.Emp_Grafico_tipo_vendas.id,
      },
      {
        title: "Market Share dos Últimos 13 Meses",
        route: "reports-last-13-months-market-share-chart",
        action: "manage",
        resource: events.Emp_Grafico_market_share_ultimos_13_meses.id,
      },
      {
        title: "Market Share",
        route: "reports-market-share-graph",
        action: "manage",
        resource: events.Emp_Grafico_market_share.id,
      },
      {
        title: "Market Share Regional",
        route: "reports-regional-market-share-graph",
        action: "manage",
        resource: events.Emp_Grafico_market_share_regional.id,
      },
      {
        title: "Comparativo de Montadoras",
        route: "reports-automaker-comparison-graph",
        action: "manage",
        resource: events.Emp_Grafico_comparativo_de_montadoras.id,
      },
      {
        title: "Market Share Média Diária",
        route: "reports-daily-average-market-share-graph",
        action: "manage",
        resource: events.Emp_Grafico_market_share_media_diaria.id,
      },
    ],
  },
];
